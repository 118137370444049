.consentimiento-cookie {
    background-color: #fff;
    color: #000;
    width: 100%;
    z-index: 99999;
    padding: 2px 24px;
}

.consentimiento-cookie__title {
    color: #000;
    margin-top: 0;
}

.consentimiento-cookie__intro > p {
    font-size: 0.9rem;
}

.consentimiento-cookie__read-more {
    font-weight: bold;
    color: #000;
    text-decoration: none;
    display: block;
}

.consentimiento-cookie__read-more:hover {
    text-decoration: underline;
}

.consentimiento-cookie__form {
    margin-top: 40px;
}

.consentimiento-cookie__category {
    border-bottom: 1px solid #000;
    padding: 30px 0;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 641px) {
    .consentimiento-cookie__category {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.consentimiento-cookie__category:first-child {
    border-top: 1px solid #000;
}

.consentimiento-cookie__category-toggle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media screen and (max-width: 641px) {
    .consentimiento-cookie__category-toggle {
        margin-top: 20px;
    }
}

.consentimiento-cookie__category-toggle input {
    opacity: 0;
    position: absolute;
    left: -10000px;
}

.consentimiento-cookie__category-toggle input + label {
    background-repeat: no-repeat;
    background-position: 45px center;
    background-color: #fff;
    color: #223462;
    border: 1px solid #223462;
    transition: all 0.2s;
    display: inline-block;
    margin-right: 15px;
    padding: 6px 28px 6px 10px;
    cursor: pointer;
    border-radius: 6px;
    width: 75px;
}

.consentimiento-cookie__category-toggle input + label::before, .consentimiento-cookie__category-toggle input + label::after {
    content: '';
}

.consentimiento-cookie__category-toggle input:checked + label, .consentimiento-cookie__category-toggle input + label:hover {
    background-color: #223462;
    color: #fff;
    border-color: #fff;
}

.consentimiento-cookie__category-toggle input:checked + label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='#fff' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.consentimiento-cookie__category-title {
    color: #000;
    margin: 0;
}

.consentimiento-cookie__category-description {
    margin: 0;
}

.consentimiento-cookie__btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.consentimiento-cookie__btn-group .consentimiento-cookie__btn {
    margin-right: 30px;
}

.consentimiento-cookie__btn-group .consentimiento-cookie__toggle-details {
    cursor: pointer;
    margin-bottom: 25px;
}

.consentimiento-cookie__btn-group .consentimiento-cookie__toggle-details-hide {
    display: none;
}

.consentimiento-cookie__btn-wrapper {
    margin-bottom: 25px;
}

.consentimiento-cookie__btn-wrapper:nth-child(2) {
    flex-grow: 1;
}

@media screen and (max-width: 861px) {
    .consentimiento-cookie__btn-wrapper {
        width: 100%;
    }
}

.consentimiento-cookie__btn {
    background: linear-gradient(349.19deg, #cf1e34 0%, #ee365f 100%);
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 26px 10px -21px rgba(0, 0, 0, .28);
}

@media screen and (max-width: 641px) {
    .consentimiento-cookie__btn {
        width: 100%;
        display: inline-block;
    }
}

.consentimiento-cookie__btn--secondary {
    background: transparent;
    color: #000;
    border: 2px solid #000;
}

.consentimiento-cookie--dark-theme {
    background-color: #000;
    color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__title, .consentimiento-cookie--dark-theme .consentimiento-cookie__read-more {
    color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category {
    border-color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category:first-child {
    border-color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category-toggle input + label {
    background-color: #000;
    color: #fff;
    border-color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category-toggle input:checked + label, .consentimiento-cookie--dark-theme .consentimiento-cookie__category-toggle input + label:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category-toggle input:checked + label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='#000' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__category-title {
    color: #fff;
}

.consentimiento-cookie--dark-theme .consentimiento-cookie__btn--secondary {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
}

.consentimiento-cookie--simplified .consentimiento-cookie__form {
    flex-direction: column-reverse;
    display: flex;
}

.consentimiento-cookie--simplified .consentimiento-cookie__category-group {
    display: none;
}

.consentimiento-cookie--simplified .consentimiento-cookie__category-toggle {
    display: none;
}

.consentimiento-cookie--simplified .consentimiento-cookie__btn-group {
    margin-top: 0;
    margin-bottom: 25px;
}
