/*
 * Copyright Peñalara Software S.L.
 */

@import "colores.scss";

footer {
    background-color: #333;
    color: #ddd;
    font-size: 0.9em;
    
    h4 {
        font-size: 1.1em;
        font-weight: 600; 
        text-transform: uppercase;
    }
    
    ul {
        
        li {
            
            padding-bottom: 0.5rem;
            list-style: disclosure-closed;
            
        }
        
    }
    
    a {
        color: #ddd;
        
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
    
    .rrss {
        
        a {
            
            font-size: 1.5em;
            padding-right: 0.25rem;
            
            &.instagram {
                &:hover {
                    color: $color-instagram;
                }
            }

            &.facebook {
                &:hover {
                    color: $color-facebook;
                }
            }

            &.twitter {
                &:hover {
                    color: $color-twitter;
                }
            }

            &.youtube {
                &:hover {
                    color: $color-youtube;
                }
            }

            &.linkedin {
                &:hover {
                    color: $color-linkedin;
                }
            }
        }
        
    }
    
    #copyrights {
        background-color: rgba(0,0,0,0.2);
        
        a, p {
            color: #aaa;
        }
    }
}