/*
 * Copyright Peñalara Software S.L.
 */

.pb-10 {
    padding-bottom: 6rem;
}

.mt-n7 {
    margin-top: -4rem;
}

$enable-negative-margins: true;

@import "colores.scss";
@import "fuentes.scss";
@import "~bootstrap/scss/bootstrap";



// BODY
body {
    font-family: $fuente-lato;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    
    
    a{
        text-decoration: none;
        color: $color-penalara;
        
        &:hover {
            color: #222;
        }
        
        img {
            border: none;
        }
    }
    
    img {
        max-width: 100%;
    }
    
    iframe {
        border: none;
    }
    
    .text-justify {
        text-align: justify;
    }
}

.separator-bottom:after {
    content: '';
    display: block;
    margin: 2rem auto;
    width: 2.5rem;
    border-top: 2pt solid #444;
}

.separator-light:after {
    content: '';
    display: block;
    margin: 1rem auto;
    width: 6.5rem;
    border-top: 1pt solid rgba(0,0,0,0.1)
}

//genérico de las páginas de información
.page-title {
    background-color: #f5f5f5;
    
    p {
        color: #777;
    }
}

.subtitulo-info {
    line-height: 1.8;
}

#integracion-penalara {
    background-color: $color-penalara;
    color: #fff;
    
    a {
       color: #fff; 
       
       &:hover {
           color: #222;
       }
    }
}

// COOKIES

#cookies_modal {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    border: 2px solid $color-penalara;
    z-index: 100;
}

.ch-cookie-consent__btn-wrapper:nth-child(2) {
    flex-grow: 0 !important;
}

.btn-config-cookies {
    background-color: $landing-txt-primary;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 26px 10px -21px rgba(0, 0, 0, .28);
}

.btn-config-cookies:hover {
    background-color: $landing-txt-primary;
    color: #fff;
    opacity: 0.85;
}

// ---------------- //
//      BOTONES     //
// ---------------- //

.boton-principal {
    color: #fff;
    border-radius: 3pt;
    border-bottom: 3pt solid rgba(0,0,0,0.15);
    display: inline-block;
    padding: 0.7rem 1.25rem 0.5rem 1.25rem;
    font-size: 1em;
    margin: 0.3125rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1pt;
    text-shadow: 1pt 1pt 1pt rgba(0,0,0,0.2);
    
    &:hover {
        opacity: 0.9;
        color: #fff;
        text-decoration: underline;
    }
}

.boton-small{
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 0.8em;
}

.boton-naranja {
    background-color: $color-penalara;
    color: #fff;
    
    &:hover {
        opacity: 0.85;
        color: #fff;
        transition: 0.2s ease-in-out;
    }
}

.boton-rojo {
    background-color: #4984AE;
}

.boton-negro {
    border: 2pt solid #444;
    border-radius: 1pt;
    color: #444;

    &:hover {
        background-color: #444;
        color: #fff;
        transition: 0.2s ease-in-out;
    }
}

.boton-azul {
    background-color: $landing-primary;
    border-color: $landing-primary;
    color: #fff;
    
    &:hover {
        background-color: $landing-primary;
        border-color: $landing-primary;
        opacity: 0.85;
        color: #fff;
        transition: 0.2s ease-in-out;
    }
}

.youtube-icon {
    width: 2rem;
}

//FILTROS
.boton-filtro {
        
    border: 1pt solid #dee2e6;

    &.active {
        border-color: $color-penalara;
        background-color: $color-penalara;
        color: #fff;
    }

    &:hover {
        cursor: pointer;
        background-color: $color-penalara;
        color: #fff;
    }

}

//GENÉRICOS
.link-img {
    &:hover {
        cursor: pointer;
    }
}


// ----------------- //
//  BLOQUE BOTONERA  //
// ----------------- //

#botonera {
    background-color: #f5f5f5;
}



//caso particular de colocación del botón en la parte baja de un bloque mucho mayor de altura 
//(lleva position: absolute en el elemento html como clase de bootstrap)
.boton-abajo {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

// ---------------- //
//    FORMULARIOS   //
// ---------------- //

label.required:before {
    content: "*";
}


// ---------------- //
// PÁGINA DE INICIO //
// ---------------- //

#caracteristicas-home {
    
    .text-caracteristicas {
        line-height: 1.8;
        color: #999;
    }
    
}

#videotutoriales-home {
    
    &.background-green {
        background-color: #AEDBC7;
    }
    
    .videotutoriales-title {
        font-family: $fuente-crete;
    }
    
}

#caracteristicas {
    
    .icono-caracteristicas {
        margin-top: 0.35rem;
    }
    
}
#testimonios-home {
    
    background-color: #f9f9f9;
    
    #carouselTestimonios {
        min-height: 16.25rem;

        .carousel-indicators {

            button {
                background-color: $color-penalara;
            }

        }

        .testimonio {
            font-family: $fuente-crete;
            font-size: 1.1em;
        }

        .provincia {
            font-size: 0.85em;
            color: #999;
        }
    }
}

#clientes {
    
    .celda {
        min-height: 5rem;
    }
}

#numeroCentros {
    background-color: #f29407;
    color: #fff;
    
    i {
        font-size: 4em;
    }
}


// ------------------ //
// PÁGINA DE PENALARA //
// ------------------ //

#pag-penalara {
    
    .icon {
        position: relative;
        width: 6rem;
        height: 6rem;
        margin: 0 auto 2rem;
        border: 1pt solid $color-penalara;
        border-radius: 50%;
        padding: 0.1875rem;
        
        i {
            line-height: 5.5rem;
            color: #fff;
            background-color: $color-penalara;
            font-size: 3em;
            border-radius: 50%;
            width: 5.5rem;
            height: 5.5rem;
        }
    }
    
    .descripcion {
        font-family: $fuente-raleway;
    }
}


// -------------------- //
// PÁGINA DE QUE ES GHC //
// -------------------- //

#pag-queesghc {
    
    .texto-partes-ghc {
        color: #999;
        line-height: 1.8;
    }
    
    .img-partes-ghc {
        max-height: 20rem;
    }
    
}

// -------------------- //
// PÁGINA DE NOVEDADES  //
// -------------------- //

#pag-novedades {
    
    .bg-gris {
        background-color: #d0d0d0;
    }
    
    .text-condiciones {
        line-height: 1.8;
    }
    
}

// -------------------------------- //
//        PÁGINA DE GHCWEBAPP       //
// -------------------------------- //

#pag-ghcwebapp,
#pag-conoceghcwebapp {
    
    .separador {
        border-right: 1pt solid $color-penalara;
    }
    
    .i-overlay {
        width: 3rem;
        transform: translate(-50%,-50%);
    }
    
    .qr-bloque {
        margin-bottom: 3rem;
        
        h2 {
            font-weight: 400;
            color: #555;
            margin: 0;
        }
        
        a {
            font-size: 1.2em;
            color: #555;
        }
    }

    .qr-fondo-exterior {
        background-color: #f5f5f5;
        border-radius: 3rem;
        padding: 3rem;
    }

    .qr-fondo-interior {
        background-color: #FFE0B2;
        border-radius: 3rem;
        padding: 1rem;
    }

    .qr-fondo-interior-qrs {
        padding: 1rem 4rem 1rem;
    }

    .qr-fondo-imagen {
        background-color: #FFF;
        border-radius: 3rem;
        padding: 1rem;
        margin: 1.5rem 0;
    }

    .qr-fondo-imagen img {
        border-radius: 3rem;
    }

    .qr-subtitulo {
        margin: 0;
        font-size: 1.2em;
        padding: 0 1.8rem 1rem;
        line-height: 1.2 !important;
    }

    .qr-web {
        margin-bottom: 3rem;
    }

    .qr-escaneame {
        margin: 0;
        font-size: 1.25em;
        font-weight: 600;
        color: #444;
    }

    .qr-escaneame img {
        height: 1.25em;
    }
    
}

// -------------------------------- //
//        PÁGINA DE WEBINARS        //
// -------------------------------- //

#pag-webinars {
    
    .i-overlay {
        width: 10%;
        transform: translate(-50%,-50%);
    }
    
    .timer {
        font-family: $fuente-crete;
        color: #999;
    }
    
}

// -------------------------------- //
//       PÁGINA DE INTEGRACION      //
// -------------------------------- //

#pag-integracion {
    
    .icono-caracteristica {
        width: 6rem;
        height: 6rem;
        line-height: 6rem;
        background: $color-penalara;
        border-radius: 5pt;
        color: #fff;
    }
    
    .texto-caracteristica {
        color: #999;
        line-height: 1.8;
    }
    
    table {
        
        .background-naranja {
            color: #fff;
        }
        
    }
    
    .celda {
        
        a {
            width: 75%;
            text-align: center;
        }
        
    }
    
}

// -------------------------------- //
//    PÁGINAS DE CASOS DE ÉXITO     //
// -------------------------------- //

#pag-casosdeexito-interno {
    .imagen {
        max-height: 19rem;
    }
    
    .gradiente {
        background: linear-gradient(to right, #d24064 , #f9e796);
        height: 2pt;
    }
    
    .faldon {
        background-color: #f3f3f3;
    }
}

// ---------------------------- //
//    PÁGINAS DE TESTIMONIOS    //
// ---------------------------- //

#pag-testimonios {
    
    .testimonio-ancla {
        top: -6rem;
        position: relative;
    }
    
    .card-testimonio {
        
        box-shadow: 2px 2px 2px #bf8a6333;
        
        .card-header {
            background-color: #eaaa7b1f;
        
            .card-ciudad {
                font-size: 0.75em;
                color: #999;
            }
        }
        
        .card-text {
            font-family: $fuente-crete;
            
            &:before, &:after {
                content: '"';
            }
            
        }
        
    }
    
}

// ---------------------------- //
//      PÁGINAS DE PRECIOS      //
// ---------------------------- //

#pag-precios {
    
    .tarjeta {
        margin-top: 1.25rem;
        border: 1pt solid rgba(0,0,0,0.075);
        border-radius: 3pt;
        box-shadow: 0 1pt 1pt rgba(0,0,0,0.1);
        background-color: #F5F5F5;
    }
    
    .tarjeta-light {
        margin: 0 -2pt;
        border: 1pt solid rgba(0,0,0,0.075);
        border-radius: 3pt;
        box-shadow: 0 0 8px rgba(0,0,0,0.1);
        background-color: #fff;
        
        .bloque-precio {
        
            p {
                color: #999;
            }

            .precio {
                font-size: 60px;
            }
        }
    }
    
    .nota-co {
        font-size: 0.75em;
    }
    
    ul {
        li {
            font-size: 0.875em;
            margin-bottom: 1rem;
        }
    }
    
}

//conversor de moneda de modal de precios
.conversor-top-block {
    border: 2pt solid $color-penalara;
    
    .separador {
        border-bottom: 1pt solid $color-penalara;
    }
    
    .valor-inicial {
        height: 2.375em;
        line-height: 2.375em;
        border: 1pt solid #ced4da;
    }
}

.conversor-bottom-block {
    min-height: 6.25rem;
    border: 2pt solid #B5CB63;
}


// ---------------------------- //
//       PÁGINA DE PROCESO      //
//        DE SUSCRIPCIÓN        //
// ---------------------------- //

#pag-procesodesuscripcion {
    
    .block {
        min-height: 20rem;
        margin-bottom: 4rem;
        
        .number {
            position: absolute;
            width: 6rem;
            height: 6rem;
            line-height: 5.75rem;
            left: 50%;
            transform: translateX(-50%);
            top: -3.1rem;
            border: 1px solid $color-penalara;
            border-radius: 50%;
            color: $color-penalara;
            background-color: #fff;
        }

        .text {
            font-family: $fuente-raleway;
        }
        
    }
    
}


// --------------------------- //
//       PÁGINA DE FORMAS      //
//           DE PAGO           //
// --------------------------- //

#pag-formasdepago {
    
    img{
        max-width: 100px;
    }
    
    .background-gris {
        background-color: #f2f2f2;
    }
    
}

// --------------------------- //
//     PÁGINA DE SEGURIDAD     //
// --------------------------- //

#pag-seguridad {
    
    .icono-caracteristica {
        width: 6rem;
        height: 6rem;
        line-height: 6rem;
        background: $color-penalara;
        border-radius: 5pt;
        color: #fff;
    }
    
    .texto-caracteristica {
        color: #999;
        line-height: 1.8;
    }
    
}

// --------------------------- //
//  PÁGINA DE VIDEOTUTORIALES  //
// --------------------------- //

#pag-videotutoriales {
 
    .i-overlay {
        width: 10%;
        transform: translate(-50%,-50%);
    }
    
    .timer {
        font-family: $fuente-crete;
        color: #999;
    }
    
}

// --------------------------- //
//      PÁGINA DE NOTICIAS     //
// --------------------------- //

#pag-noticias, #pag-webinars {
    
    .border-timeline {
        border-left: 2pt dotted #ccc;
        
        &:hover {
            .date {
                border-color: $color-penalara;
                color: $color-penalara;
                transition: 0.3s ease-in-out;
            }
        }
        
    }
    
    .date {
        width: 4rem;
        height: 4rem;
        border: 3px solid #ccc;
        border-radius: 50%;
        color: #ccc;
        margin-left: -2.8125rem;
        margin-top: 1.25rem;
        background: #fff;
        z-index: 1;
        position: relative;
        
        .day {
            margin: -0.3125rem;
        }
        
        .month {
            margin: -0.1875rem;
            font-size: 0.8em;
        }
    }
    
    .separador-timeline {
        width: 4rem;
        position: absolute;
        margin-top: -2rem;
        border-bottom: 2pt dotted #ccc;
    }
    
    .boton-principal {
        padding: 0.35rem 1rem 0.35rem 1rem;
    }
    
}

// --------------------------- //
//    PÁGINA DE DESIDERATAS    //
// --------------------------- //

#pag-desideratas {
    .icono-caracteristica {
        width: 6rem;
        height: 6rem;
        line-height: 6rem;
        background: $color-penalara;
        border-radius: 5pt;
        color: #fff;
    }
    
    .texto-caracteristica {
        color: #999;
        line-height: 1.8;
    }
    
    .icono-danger {
        width: 6rem;
        height: 6rem;
        line-height: 5.5rem;
        border-radius: 50%;
        border: 1pt solid $color-penalara;
        color: $color-penalara;
    }
}

// --------------------------- //
//     PÁGINA DE CONTACTO      //
// --------------------------- //
#pag-contacto {
    
    .bloque-info {
        font-family: $fuente-raleway;
        
        &.contacto {
            height: 11rem;
        }
        
        a {
            color: #555;
        }
        
        

        .instagram {
            &:hover {
                color: $color-instagram;
            }
        }

        .facebook {
            &:hover {
                color: $color-facebook;
            }
        }

        .twitter {
            &:hover {
                color: $color-twitter;
            }
        }

        .youtube {
            &:hover {
                color: $color-youtube;
            }
        }

        .linkedin {
            &:hover {
                color: $color-linkedin;
            }
        }
        
        .skype {
            &:hover {
                color: $color-skype;
            }
        }
            
    }
    
    .icono {
        font-size: 5em;
        width: 8rem;
        top: -0.75em;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        font-family: $fuente-raleway;
    }
    
}


// --------------------------- //
//    PÁGINA DE AVISO LEGAL    //
// --------------------------- //

#pag-avisolegal {
    .horario {
        font-family: $fuente-crete;
        background-color: #f5f5f5;
    }
}

// --------------------------- //
//     SECCIÓN INTEGRACIÓN     //
// --------------------------- //
#guia {
    .bg-code {
        background-color: #eee;
        padding: 2rem;
    }
}



// -------------------- //
//    PÁGINA DE LOGIN   //
// -------------------- //

#pag-login {
    .bloques-login {
        background-color: #f5f5f5;
    }
    
    .aviso {
        font-size: 0.85em;
    }
    
    .columna-app {
        height: 90%;
    }
}

.img-redonda-login {
    width: 7rem;
}

// ---------------- //
//   MEDIA QUERIES  //
// ---------------- //

@media screen and (max-width: 992px) {

    #pag-pautashorarioscovid,
    #pag-ghcwebapp {
    
        .separador {
            border-right: 0;
        }
    
    }
}

@media screen and (max-width: 768px) {
 
    
    // ---------------- //
    //    MEDIA QUERY   //
    // PÁGINA DE INICIO //
    // ---------------- //
    #clientes {
        
        .celda {
            border: none !important;
            min-height: 0;
            padding: 0.2rem !important;
        }
        
    }
    
    // --------------------- //
    //       MEDIA QUERY     //
    // PÁGINA DE INTEGRACIÓN //
    // --------------------- //
    #pag-integracion {
        
        .celda {
            border-bottom: 1px solid;
            border-right: none !important;
        }
        
    }
    
    // ------------------------ //
    //        MEDIA QUERY       //
    // PÁGINA DE CASOS DE ÉXITO //
    // ------------------------ //
    #pag-casosdeexito {
        .border-end {
            border: none !important;
        }
    }
    
    // ------------------------ //
    //        MEDIA QUERY       //
    //   PÁGINA DE TESTIMONIOS  //
    // ------------------------ //
    #pag-testimonios {
    
        .border-right-testimonio {
            border-right: none;
        }

        .border-top-testimonio {
            border-top: none;
        }

    }
    
}

@media screen and (max-width: 576px) {
    
    // ------------------------ //
    //        MEDIA QUERY       //
    //    PÁGINA DE NOTICIAS    //
    // ------------------------ //
    #pag-noticias {
        .border-timeline {
            width: 100% !important;
            border: none;
        }
        
        .date,
        .separador-timeline {
            display: none !important;
        }
    }
}
