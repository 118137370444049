@import "colores.scss";

#mapa {
    
    margin-top: 4rem;
    
    .seccion-captadesideratas {
        margin-top: 0.3rem;
    }
    
    .seccion-planificador {
        margin-top: -0.5rem;
    }
    
    .seccion-motor {
        margin-top: -1.4rem;
    }
    
    .seccion-editor {
        margin-top: 0.9rem;
    }
    
    .seccion-visor {
        margin-top: -1.4rem;
    }
    
    .seccion-ausencias {
        margin-top: -0.7rem;
    }
    
    .seccion-informes {
        margin-top: 0.3rem;
    }
    
    .mapa-imagen {
        position: absolute;
        max-width: 11rem;
        left: 50%;
        transform: translateX(-50%);
    }
    
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    
    #mapa {
        
        .seccion-editor {
            margin-top: -0.5rem;
        }

        .seccion-visor {
            margin-top: -1.65rem;
        }

        .seccion-ausencias {
            margin-top: -0.5rem;
        }

        .seccion-informes {
            margin-top: -1.25rem;
        }
        
    }
    
    #videotutoriales {
        margin-top: 2rem !important;
    }
    
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    
    #mapa {
        
        .seccion-captadesideratas {
            margin-top: 0.3rem;
        }

        .seccion-planificador {
            margin-top: -0.5rem;
        }

        .seccion-motor {
            margin-top: -1.4rem;
        }

        .seccion-editor {
            margin-top: -0.6rem;
        }

        .seccion-visor {
            margin-top: -1.5rem;
        }

        .seccion-ausencias {
            margin-top: -0.7rem;
        }

        .seccion-informes {
            margin-top: -2.95rem;
        }
        
    }
    
    #videotutoriales {
        margin-top: 2rem !important;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    
    #mapa {
        
        .mapa-imagen {
            max-width: 8rem;
        }
        
        .seccion-captadesideratas {
            margin-top: 0;
        }
        
        .seccion-planificador {
            margin-top: -3.1rem;
        }
    
        .seccion-motor {
            margin-top: -2.5rem;
        }
        
        .seccion-editor {
            margin-top: -1.85rem;
        }
        
        .seccion-visor {
            margin-top: -2.5rem;
        }

        .seccion-ausencias {
            margin-top: -1.9rem;
        }

        .seccion-informes {
            margin-top: -4.1rem;
        }
        
        .descripcion {
            font-size: 0.85em;
        }
        
    }
    
    #videotutoriales {
        margin-top: 2rem !important;
    }
    
}

@media screen and (max-width: 767px) {
    
    #mapa {
        
        h2 {
            margin-bottom: 1.5rem;
        }
        
        .mapa-imagen {
            display: none;
        }
        
        .seccion-captadesideratas, .seccion-planificador,
        .seccion-motor, .seccion-editor,
        .seccion-visor, .seccion-ausencias, .seccion-informes  {
            margin-top: 0;
            
            p {
                text-align: left !important;
            }
        }
        
    }
    
}