/*
 * Copyright Peñalara Software S.L.
 */

@import "colores.scss";

#slider {
    
    font-family: 'Raleway', sans-serif;
    
    .link-slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    
    .carousel-item {
        min-height: 31.25rem;
        background: no-repeat center center scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    
    .carousel-control-prev,
    .carousel-control-next {
        width: 5%;
        z-index: 11;
    }
    
    .carousel-control-prev-icon {
        position: absolute;
        left: 0;
        width: 3rem;
        height: 3rem;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0 0.3rem 0.3rem 0;
    }
    
    .carousel-control-next-icon {
        position: absolute;
        right: 0;
        width: 3rem;
        height: 3rem;
        background-color: rgba(0,0,0,0.4);
        border-radius: 0.3rem 0 0 0.3rem;
    }
    
    .slider-izq {
        
        &.slider-principal {
            background-color: #F8C983;
            
            .slogan-principal {
                padding: 5rem 0 3rem 6rem;
            }
            
            .sub-slogan {
                font-size: 1.9rem;
                padding: 0 0 0 6rem;
            }
        }
        
        &.slider-web-app {
            background-color: #81C6C2;
            
            .slogan-principal {
                padding: 5rem 0 2rem 6rem;
            }
            
            .sub-slogan {
                font-size: 1.75rem;
                padding: 0 0 0 6rem;
                
                &.linea-2 {
                    margin-left: 1rem;
                }
                
                &.linea-3 {
                    margin-left: 2rem;
                }
                
                &.linea-4 {
                    margin-left: 3rem;
                }
            }
            
            .slider-button-principal {
                bottom: 8.5rem;
            }
        }
        
        &.slider-lite {
            
            background-color: $background-lite;
            
            .slogan-principal {
                padding: 7rem 0 2rem 14rem;
                font-size: 3.5rem;
            }
            
            .sub-slogan {
                font-size: 1.6rem;
                padding: 0 0 0 14rem;
                width: 80%;
            }
            
            .img-lite {
                width: 26rem;
                margin-top: 3rem;
                margin-right: 14rem;
            }
            
            .btn-lite {
                background-color: $color-penalara;
            }
            
        }
        
        .slider-button-principal {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 6.5rem;
            background-color: white;
            width: 20rem;
            padding: 1rem;
            border-radius: 2rem;
        }
        
        .slogan-principal {
            font-size: 3rem;
            color: #0D3A5C;
            line-height: normal;
            width: 85%;
        }
        
        .sub-slogan {
            font-family: "Raleway", sans-serif;
            color: #0D3A5C;
        }
        
        .img-slider-right {
            padding-right: 6rem;
            margin-top: 1rem;
        }
        
        .img-edificio {
            max-height: 30rem;
            width: 40rem;
            margin-top: 7rem;
        }
        
    }
    
    .slider-dcha {
        
        &.slider-webinars {
            background-color: #A5CEDC;
        }
        
        &.slider-ia {
            background-color: #A8D4C7;
            
            .bloque-slogan {
                padding: 8rem 6rem 1rem;
            }
            
            .sub-slogan {
                font-size: 1.9rem;
                font-family: "Raleway", sans-serif;
                color: #0D3A5C;
            }
            
            .img-slider-left {
                margin-top: 2%;
            }
            
        }
        
        &.slider-soporte {
            background-color: #A5CEDC;
            
            .img-slider-left {
                margin-top: 2%;
                margin-left: 6rem;
            }
        }
        
        .bloque-slogan {
            padding: 8rem 12rem 3rem;
        }
         
        .slogan-principal {
            font-size: 3rem;
            color: #0D3A5C;
            line-height: normal;
        }
        
        .slider-button-principal {
            background-color: white;
            width: 20rem;
            padding: 1rem;
            border-radius: 2rem;
        }
    }
    
    
    
    .slider-1 {
        
        .text-block {
            position: absolute;
            width: 42rem;
            left: 46.5%;
            top: 24%;

            .slogan {
                font-weight: 600;
                font-size: 3em;
                color: $color-penalara;
                text-shadow: 1px 1px 2px lightgray;
            }
            
        }
        
    }
    
    .slider-2 {
        
        .text-block {
            width: 50rem;
            padding: 1.5rem;
                
            .icon-GHC{
                background-color: #fff;
                height: 7.5rem;
                width: 7.5rem;
                border-radius: 50%;
                
                span {
                    font-size: 3.125em;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            
            p {
                color: #fff;
                font-size: 1.5em;
                
                &.title {
                    font-size: 2.8em;
                    top: 1.3rem;
                }
                
                &.text-list-1 {
                    margin-left: 10%;
                }
                
                &.text-list-2 {
                    margin-left: 18%;
                }
                
                &.text-list-3 {
                    margin-left: 26%;
                }
            }
        }
        
        .slider-button {
            position: relative;
            left: 6rem;
            margin-top: 3rem !important;
        }
        
        .button-cursor-pointer {
            bottom: -60%;
            right: 12%;
        }
        
    }
    
    .slider-3 {
        
        .text-block {

            color: #fff;
                
            .slogan {

                margin-top: 2.5rem;
                
                p {
                    font-size: 2.5em;
                }

            }

            .play-video-text {

                margin-top: 12rem;
                
                p {
                    font-size: 1.8em;
                    font-weight: 800;
                }
            }
        }
        
    }
    
    .slider-4 {
        
        .text-block {
            position: absolute;
            right: 0;
            width: 55rem;
            padding: 6rem 5rem;
            color: #fff;
            
            p { 
                font-size: 2.5em;
            }
        }
        
        .slider-button {
            position: relative;
            margin-top: 4rem;
        }
        
        .button-cursor-pointer {
            bottom: -60%;
            right: 12%;
        }
        
    }
    
    .slider-button {
        font-size: 1.5em;
        font-weight: 600;
        background-color: #fff;
        width: 18.75rem;
        padding: 1.25rem 1rem;
        border-radius: 2.5rem;
        box-shadow: 1rem 1rem 1rem -0.6rem rgba(34, 34, 34, 0.9)
    }
    
    .button-cursor-pointer {
        position: absolute;
        width: 4.5rem;
        bottom: -15%;
        right: 25%;
    }
    
}

@media screen and (max-width: 1400px) {
    
    #slider {
    
        .slider-izq {

            &.slider-principal {

                .slogan-principal {
                    padding: 6rem 0 3rem 5rem;
                }

                .sub-slogan {
                    font-size: 1.7rem;
                    padding: 0 0 0 5rem;
                }
                
            }
            
            &.slider-web-app {
                
                .slogan-principal {
                    padding: 4rem 0 1rem 6rem;
                }

                .slider-button-principal {
                    bottom: 3.5rem;
                    left: 12rem;
                    transform: none;
                }
                
                .sub-slogan {
                    
                    font-size: 1.5rem;
                    
                    &.linea-1 {
                        margin-left: 1rem;
                    }
                    
                    &.linea-2 {
                        margin-left: 2rem;
                    }

                    &.linea-3 {
                        margin-left: 3rem;
                    }

                    &.linea-4 {
                        margin-left: 4rem;
                    }
                    
                }
                
            }
            
            &.slider-lite {
            
                .slogan-principal {
                    padding: 7rem 0 2rem 6rem;
                }

                .sub-slogan {
                    padding: 0 0 0 6rem;
                }

                .img-lite {
                    width: 20rem;
                    margin-top: 3rem;
                    margin-right: 6rem;
                }
                
                .slider-button-principal {
                    bottom: 3.5rem;
                }

            }

            .slogan-principal {
                font-size: 3.25rem;
                width: 90%;
            }
            
            .img-slider-right {
                padding-right: 0rem;
                margin-top: 2rem;
                margin-right: 2rem;
            }
            
            .slider-button-principal {
                bottom: 8.5rem;
                width: 18rem;
                font-size: 1.25em !important;
            }
            
            .img-edificio {
                width: 27rem;
                margin-top: 8rem;
                margin-right: 4rem;
            }

        }
        
        .slider-dcha {
            
            &.slider-ia {

                .bloque-slogan {
                    padding: 7rem 3rem 1rem;
                }

                .img-slider-left {
                    margin-top: 6%;
                }
                
                .slogan-principal {
                    font-size: 2.3rem;
                }

            }
            
            .bloque-slogan {
                padding: 8rem 4rem 3rem;
            }
            
            .img-slider-left {
                margin-top: 5rem;
            }
            
        }
        
    
    }
    
    
    
    #slider {
        .slider-1 {
        
            .text-block {
                width: 45rem;
                left: 40.5%;
                top: 25%;

                .slogan {
                    font-size: 3em;
                }
            }

        }
        
        .slider-2 {
            .text-block {

                width: 40rem;
                
                .icon-GHC{
                    height: 5.5rem;
                    width: 5.5rem;

                    span {
                        font-size: 2.5em;
                    }
                }

                p {
                    
                    &.title {
                        font-size: 2.3em;
                        top: 0.85rem;
                    }

                    &.text-list-1 {
                        margin-left: 5%;
                    }

                    &.text-list-2 {
                        margin-left: 14%;
                    }

                    &.text-list-3 {
                        margin-left: 23%;
                    }
                }
            }
            
        }
        
        .slider-4 {
        
            .text-block {
                width: 38rem;
                padding: 8rem 3rem;

                p { 
                    font-size: 2em;
                }
            }
        }
        
    }
    
}

@media screen and (max-width: 1200px) {

    #slider {
    
        .slider-izq {

            &.slider-principal {

                .slogan-principal {
                    padding: 5rem 0 0 6rem;
                }

                .sub-slogan {
                    font-size: 1.4rem;      
                    padding: 0 0 0 6rem;
                }
                
            }

            &.slider-web-app {
                
                .slogan-principal {
                    padding: 4rem 0 2rem 5rem;
                }

                .slider-button-principal {
                    bottom: 5.5rem;
                    left: 11rem;
                    transform: none;
                    padding: 0.5rem 1rem;
                }
                
                .sub-slogan {
                    
                    font-size: 1.4rem;
                    
                    &.linea-1 {
                        margin-left: 0;
                        margin-bottom: 0.25rem;
                    }
                    
                    &.linea-2 {
                        margin-left: 1rem;
                        margin-bottom: 0.25rem;
                    }

                    &.linea-3 {
                        margin-left: 2rem;
                        margin-bottom: 0.25rem;
                    }

                    &.linea-4 {
                        margin-left: 3rem;
                        margin-bottom: 0.25rem;
                    }
                    
                }
                
            }
            
            &.slider-lite {
            
                .slogan-principal {
                    padding: 4rem 0 2rem 6rem;
                    font-size: 3rem;
                }

                .slider-button-principal {
                    bottom: 4.5rem;
                }

            }
            
            .slogan-principal {
                font-size: 2.54rem;
                width: 100%;
            }
            
            .img-slider-right {
                margin-top: 4rem;
                margin-right: 3rem;
            }
            
            .slider-button-principal {
                bottom: 6.5rem;
                width: 16rem;
                font-size: 1.15em !important;
                left: 14rem;
            }
            
            .img-edificio {
                margin-top: 7rem;
                margin-right: 7rem;
            }

        }
        
        .slider-dcha {
            
            &.slider-ia {

                .bloque-slogan {
                    padding: 8rem 3rem 1rem;
                }

                .img-slider-left {
                    margin-top: 8%;
                }
                
                .slogan-principal {
                    font-size: 2em;
                }
                
                .sub-slogan {
                    font-size: 1.6rem;
                }

            }
            
            .slogan-principal {
                font-size: 2.5rem;
            }
                        
            .img-slider-left {
                margin-top: 6rem;
            }
            
        }
    
    }
    
    
    #slider {
        .slider-1 {
        
            .text-block {
                left: 33.5%;
            }

        }
        
        .slider-2 {
            .text-block {
                
                width: 34rem;
                
                .icon-GHC{
                    height: 4.5rem;
                    width: 4.5rem;

                    span {
                        font-size: 2em;
                    }
                }

                p {
                    
                    font-size: 1.3em;
                    
                    &.title {
                        font-size: 1.9em;
                        top: 0.7rem;
                    }

                    &.text-list-1 {
                        margin-left: 0;
                    }

                    &.text-list-2 {
                        margin-left: 6%;
                    }

                    &.text-list-3 {
                        margin-left: 12%;
                    }
                }
                
                .slider-button {
                    left: 1rem; 
                }
                
            }
        }
        
        .slider-4 {
        
            background-position: right center !important;
            
        }
        
    }
    
}

@media screen and (max-width: 992px) {

    #slider {
    
        .slider-izq {

            &.slider-principal {

                .slogan-principal {
                    padding: 3rem 0 0 5rem;
                }

                .sub-slogan {
                    font-size: 1.25rem;      
                    padding: 0 0 0 5rem;
                }
                
            }
            
            &.slider-web-app {
                
                .slogan-principal {
                    padding: 2rem 0 0 5rem;
                }

                .slider-button-principal {
                    bottom: 2.5rem;
                    left: 11rem;
                    transform: none;
                    padding: 0.5rem 1rem;
                }
                
                .sub-slogan {
                    font-size: 1.1rem;
                }
                
            }
            
            &.slider-lite {
            
                .slogan-principal {
                    padding: 3rem 0 0 5rem;
                    font-size: 2.5rem;
                }

                .sub-slogan {
                    padding: 0 0 0 5rem;
                    font-size: 1.3rem;
                }

                .img-lite {
                    width: 13rem;
                }
                
                .slider-button-principal {
                    bottom: 4rem;
                }

            }

            .slogan-principal {
                font-size: 1.9rem;
            }
            
            .img-slider-right {
                margin-top: 1.5rem;
                margin-right: 3rem;
            }
            
            .slider-button-principal {
                bottom: 2.5rem;
                width: 16rem;
                font-size: 1.15em !important;
                left: 13rem;
                padding: 0.5rem 1rem;
            }

            .img-edificio {
                margin-top: 2.4rem;
            }
            
        }
        
        .slider-dcha {
            
            &.slider-ia {

                .bloque-slogan {
                    padding: 4rem 2rem 1rem;
                }

                .img-slider-left {
                    margin-top: 6%;
                }
                
                .slogan-principal {
                    font-size: 1.65em;
                }
                
                .sub-slogan {
                    font-size: 1.4rem;
                }
                
                .slider-button-principal {
                    padding: 0.5rem 2rem;
                }

            }
            
            .bloque-slogan {
                padding: 4rem 4rem 3rem;
            }
            
            .slogan-principal {
                font-size: 2.2rem;
            }
                        
            .img-slider-left {
                margin-top: 3rem;
            }
            
        }
    
    }
    
    
    
    #slider {
        
        .carousel-item {
            min-height: 21.875rem;
        }
        
        .slider-1 {
        
            .text-block {
                width: 35rem;
                top: 15%;
                left: 39%;
                
                .slogan {
                    font-size: 2.5em;
                }
            }

        }
        
        .slider-2 {
            
            .text-block {
                
                padding: 1rem;
                
                p {
                    font-size: 1em;
                }
                
                .slider-button {
                     margin-top: 1rem !important;
                }
                
            }
            
        }
        
        .slider-3 {
        
            .text-block {

                .slogan {
                    margin-top: 0.5rem;
                }
                
                .play-video-text {
                    margin-top: 7rem;
                }
                
            }

        }
        
        .slider-4 {
        
            .text-block {
                width: 30rem;
                padding: 5rem 2rem;

                p { 
                    font-size: 1.5em;
                }
            }
        }
        
        .slider-button {
            font-size: 1.2em;
            width: 15.75rem;
        }

        .button-cursor-pointer {
            width: 3.5rem;
        }
    }
    
}

@media screen and (max-width: 768px) {

    #slider {
    
        .slider-izq {

            &.slider-principal {

                .slogan-principal {
                    padding: 4rem 0 0 5rem;
                }

                .sub-slogan {
                    font-size: 1.1rem;      
                    padding: 0 0 0 5rem;
                }
                
            }
            
            &.slider-web-app {
                
                .slogan-principal {
                    padding: 2rem 0 0 5rem;
                }

                .slider-button-principal {
                    bottom: 2.5rem;
                    left: 8rem;
                    transform: none;
                    padding: 0.5rem 1rem;
                }
                
                .sub-slogan {
                    
                    font-size: 1rem;
                    
                    &.linea-1 {
                        margin-left: 0;
                    }
                    
                    &.linea-2 {
                        margin-left: 0.5rem;
                    }

                    &.linea-3 {
                        margin-left: 1rem;
                    }

                    &.linea-4 {
                        margin-left: 1.5rem;
                    }
                    
                }
                
            }
            
            &.slider-lite {
            
                .slogan-principal {
                    padding: 3rem 0 1rem 5rem;
                    font-size: 2rem;
                }

                .sub-slogan {
                    width: 100%;
                    padding: 0 0 0 5rem;
                    font-size: 1.1rem;
                }

            }

            .slogan-principal {
                font-size: 1.75rem;
                width: 100%;
            }
            
            .img-slider-right {
                margin-top: 3rem;
                margin-right: 3rem;
            }
            
            .slider-button-principal {
                bottom: 3.5rem;
                width: 16rem;
                font-size: 1.15em !important;
                left: 13rem;
                padding: 0.5rem 1rem;
            }

            .img-edificio {
                width: 18rem;
                margin-top: 6rem;
            }
            
        }
        
        .slider-dcha {
            
            &.slider-ia {

                .bloque-slogan {
                    padding: 4rem 2rem 1rem;
                }

                .img-slider-left {
                    width: 40% !important;
                    margin-top: 10%;
                }
                
                .sub-slogan {
                    font-size: 1rem;
                }
                
            }
            
            .bloque-slogan {
                padding: 5rem 1rem 2rem;
            }
            
            .slogan-principal {
                font-size: 1.5rem;
            }
                        
            .img-slider-left {
                margin-top: 0;
                width: 85% !important;
            }
            
            .slider-button-principal {
                width: 16rem;
                padding: 0.5rem 1rem;
                border-radius: 2rem;
            }
            
        }
    
    }
    
    
    #slider {
        .slider-1 {
        
            .text-block {
                width: 30rem;
                left: 33.5%;
                
                .slogan {
                    font-size: 2em;
                }
            }

        }
        
        .slider-2 {
            
            background-position: left top !important;
            
            .text-block {
                
                padding: 1rem;
                
                p {
                    font-size: 1em;
                }
                
                .slider-button {
                     margin-top: 1rem !important;
                }
                
            }
            
        }
        
    }
    
}

@media screen and (max-width: 767px) {
    
    #slider {
        display: none;
    }
    
}