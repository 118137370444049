/*
 * Copyright Peñalara Software S.L.
 */

@import "~bootstrap/scss/_functions.scss";

$color-penalara: #F29407;
$color-instagram: #C13584;
$color-facebook: #3B5998;
$color-twitter: #00ACEE;
$color-youtube: #C4302B;
$color-linkedin: #0E76A8;
$color-skype: #00AFF0;
$color-email: #6567A5;
$color-whatsapp: #25D366;

/* Sobreescribimos los colores del tema de boostrap con los nuestros. */
$primary: $color-penalara;
$secondary: shade-color($color-penalara, 50%);
// $success: $color-penalara;
$info: tint-color($color-penalara, 50%);
// $warning: $color-penalara;
// $danger: $color-penalara;
// $light: $color-penalara;
// $dar: $color-penalara;

$border-primary: $color-penalara;


//COLORES FACTURACION
$color-estado-factura-pagado: #a6e9d5;
$color-estado-factura-pendiente: #f1aeb5;

//COLORES LANDING
$landing-primary: #4984AE;
$landing-secondary: #F8C983;
$landing-bg-secondary: #DDEEFF;
$landing-txt-primary: #0D3A5C;
$landing-bg-alter: #5FABBB;
$landing-txt-testimonios: #4984AE;

$background-lite: #FAE9D7;

.blanco {
    color: #fff !important;
}

.naranja {
    color: $color-penalara !important;
}

.azul {
    color: $landing-primary !important;
}

.background-naranja {
    background-color: $color-penalara !important;
}

.background-gris {
    background-color: #f5f5f5 !important;
}
