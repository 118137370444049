/*
 * Copyright Peñalara Software S.L.
 */

@import "colores.scss";

.navbar {
    background: #fff;
    border-bottom: 0.06rem solid #EEE;
}

.navbar-logo {
    width: 115px;
}

.social {
  
    background-color: #fff;
    
    li {
        border-right: 0.06rem solid #EEE;
        
        a {
            
            &.nav-link {
                color: #666;
                padding: 0.8rem 0.75rem !important;
            }
            
        }
        
        &.tlf {
            span {
                font-size: 0.8em;
                font-weight: 700;
                color: $color-penalara;
            }
        }
        
        &.tlf-doble {
            a{
                display: inline-block;
                
                span{
                    font-size: 0.8em;
                    font-weight: 700;
                    color: $color-penalara;
                } 
            }
            
        }
        
        &:last-child {
            border-right: none;
        }
    }

    .texto {
        vertical-align: middle;
        
    }

    .icono {
        vertical-align: middle;
    }

    .expandido {
      
        span {
            color: #fff;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
        }
      
    }
    
    .instagram {
        background-color: $color-instagram;
    }
    
    .facebook {
        background-color: $color-facebook;
    }

    .twitter {
        background-color: $color-twitter;
    }

    .youtube {
        background-color: $color-youtube;
    }

    .linkedin {
        background-color: $color-linkedin;
    }

    .skype {
        background-color: $color-skype;
    }

    .correo {
        background-color: $color-email;
    }
    
    .whatsapp {
        background-color: $color-whatsapp;
    }

    .tlf, .tlf-doble {
        
        .nav-link {
            
            height: 3.25rem;
            &:hover {
                background-color: $color-penalara;
                span {
                    color: #fff;
                }
            }
        }
        
    }
    
}

.banner-publico {
    background-color: $landing-bg-secondary;
    
    .banner-texto {
        color: $landing-txt-primary;
    }
}

.sin-banner {
    background-color: #fff;
}

.fab.fa-twitter::before{
    content:"𝕏";
}

.idiomas-y-acceso {
    
    background-color: #fff;
    
    li {
        
        a {
            
            &.nav-link {
                color: #666;
                padding: 0.86rem 0.75rem !important;
            }
            
            &.acceso-usuarios {
                background-color: $color-penalara;
                color: #fff;
                font-weight: 600;
            }
            
            &.nav-registro {
                background-color: $landing-primary;
                color: #fff;
                font-weight: 600;
                padding-left: 2rem !important;
                padding-right: 2rem !important;
            }
        }
        
        #navbarDropdown {
            display: flex;
            align-items: center;
        }
        
        ul {
            margin-top: 0;
            border-radius: 0;
            border-top: none;
            
            &.dropdown-menu {
                min-width: 7rem;
            }
            
            .dropdown-item {
                display: flex;
                align-items: center;
                
                &:hover {
                    background-color: $color-penalara;
                    color: #fff;
                    transition: 0.2s;
                }
            }
            
            &.idiomas {
                padding: 0.25rem 0;
                border-top: 0.125rem solid $color-penalara;
                
                li {
                    padding: 0.25rem 0;
                    
                }
            }
            
            &.acceso-opciones {
                min-width: 24rem;
                padding: 0;
                
                .dropdown-item {
                    padding: 1rem;
                }
                    
                .menu-option-left {
                    span {
                        display: block;
                        padding: 0;
                        
                        &.top {
                            font-weight: 600;
                        }
                        
                        &.bottom {
                            font-size: 0.8em;
                            padding-left: 1rem;
                            font-style: italic;
                        }
                    }
                }
                
                .menu-option-right {
                    position: absolute;
                    right: 0;
                    
                    span {
                        font-size: 1.35em;
                        padding: 1rem;
                    }
                }

            }
        }
    }
    
}

.sticky-top {
    z-index: 999;
}

.menu-principal {
    
    height: 100px;
    background: #fff;
    
    .menu {
        background: #fff;
    }
    
    .nav-link {
        color: $color-penalara;
        font-weight: 600;
    }
    
    ul {
        
        &.dropdown-menu {
            min-width: 14rem;
            margin-top: 0;
            border-radius: 0;
            border-top: 0.125rem solid $color-penalara;
            box-shadow: 0.06rem 0.06rem 0.25rem $color-penalara;
            
            .dropdown-item {
                
                &:hover {
                    background-color: $color-penalara;
                    color: #fff;
                }
                
            }
        }
        
    }
    
}

@media screen and (max-width: 991px) {
    .menu-principal {
        .menu {
            .boton-naranja, .boton-azul {
                margin-bottom: 1rem;
            }
        }
    }
    
    .logo-header {
        padding: 0 0.75rem !important;
    }
    
}